exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affiliate-program-js": () => import("./../../../src/pages/affiliate-program.js" /* webpackChunkName: "component---src-pages-affiliate-program-js" */),
  "component---src-pages-cookie-statement-js": () => import("./../../../src/pages/cookie-statement.js" /* webpackChunkName: "component---src-pages-cookie-statement-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-homepage-1-js": () => import("./../../../src/pages/homepage1.js" /* webpackChunkName: "component---src-pages-homepage-1-js" */),
  "component---src-pages-homepage-2-js": () => import("./../../../src/pages/homepage2.js" /* webpackChunkName: "component---src-pages-homepage-2-js" */),
  "component---src-pages-homepage-3-js": () => import("./../../../src/pages/homepage3.js" /* webpackChunkName: "component---src-pages-homepage-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifetime-deal-js": () => import("./../../../src/pages/lifetime-deal.js" /* webpackChunkName: "component---src-pages-lifetime-deal-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-tour-js": () => import("./../../../src/pages/product-tour.js" /* webpackChunkName: "component---src-pages-product-tour-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-write-for-us-js": () => import("./../../../src/pages/write-for-us.js" /* webpackChunkName: "component---src-pages-write-for-us-js" */),
  "component---src-templates-features-js": () => import("./../../../src/templates/features.js" /* webpackChunkName: "component---src-templates-features-js" */),
  "component---src-templates-landings-js": () => import("./../../../src/templates/landings.js" /* webpackChunkName: "component---src-templates-landings-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */)
}

